import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { up, down } from 'slide-element';

const swiperKeynote = document.querySelectorAll('.block-swiper-keynote-medias');

const updateActiveSlide = (swiperInstance) => {
    const slides = document.querySelectorAll('.block-infos-slide');
    const { realIndex } = swiperInstance;
    slides.forEach((slide, index) => {
        const content = slide.querySelector('.block-infos-slide-content');
        if (index === realIndex) {
            down(content);
            slide.classList.add('active');
        } else {
            up(content);
            slide.classList.remove('active');
        }
    });
};

const getDirection = () => (window.innerWidth < 960 ? 'horizontal' : 'vertical');

const slides = document.querySelectorAll('.swiper-slide');

slides.forEach((slide) => {
    const video = slide.querySelector('video');
    if (!video) return; // Skip if no video in the slide

    const playButton = slide.querySelector('.btn-play');
    const volumeButton = slide.querySelector('.btn-volume');
    const fullscreenButton = slide.querySelector('.btn-fullscreen');

    // Play/Pause
    playButton.addEventListener('click', () => {
        if (video.paused) {
            video.play();
            playButton.querySelector('.icon-play').style.display = 'none';
            playButton.querySelector('.icon-pause').style.display = 'inline-block';
        } else {
            video.pause();
            playButton.querySelector('.icon-pause').style.display = 'none';
            playButton.querySelector('.icon-play').style.display = 'inline-block';
        }
    });

    // Volume
    volumeButton.addEventListener('click', () => {
        video.muted = !video.muted;
        if (video.muted) {
            volumeButton.querySelector('.icon-volume').style.display = 'none';
            volumeButton.querySelector('.icon-volume-muted').style.display = 'inline-block';
        } else {
            volumeButton.querySelector('.icon-volume-muted').style.display = 'none';
            volumeButton.querySelector('.icon-volume').style.display = 'inline-block';
        }
    });

    // Fullscreen
    fullscreenButton.addEventListener('click', () => {
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.webkitRequestFullscreen) { // Safari
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) { // IE/Edge
            video.msRequestFullscreen();
        }
    });
});

swiperKeynote.forEach((element) => {
    const swiperInstance = new Swiper(element, {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
        direction: getDirection(),
        height: 572,
        loop: true,
        navigation: {
            nextEl: element.querySelector('.swiper-button-next'),
            prevEl: element.querySelector('.swiper-button-prev'),
        },
        pagination: {
            el: element.querySelector('.swiper-pagination'),
        },
        on: {
            init: (instance) => {
                updateActiveSlide(instance);

                const playVideoInSlide = (index) => {
                    const video = instance.slides[index]?.querySelector('video');
                    const img = instance.slides[index]?.querySelector('img');
                    if (video) {
                        instance.autoplay.stop();
                        video.currentTime = 0;
                        video.play();

                        video.onended = () => {
                            if (instance.activeIndex === index) {
                                instance.slideNext();
                            }
                        };
                    } else if (img) {
                        instance.params.autoplay.delay = 10000; // 10 seconds delay for images
                        instance.autoplay.start();
                    }
                };

                playVideoInSlide(instance.activeIndex);

                instance.playVideoInSlide = playVideoInSlide;

                // Attach click event to .block-infos-slide-btn
                const slideBtns = document.querySelectorAll('.block-infos-slide-btn');
                slideBtns.forEach((btn, index) => {
                    btn.addEventListener('click', () => {
                        instance.slideToLoop(index);
                        instance.once('slideChangeTransitionEnd', () => {
                            updateActiveSlide(instance);
                        });
                    });
                });
            },
            slideChangeTransitionStart: (instance) => {
                updateActiveSlide(instance);
                instance.playVideoInSlide(instance.activeIndex);
            },
        },
    });

    // Update direction on resize
    window.addEventListener('resize', () => {
        const newDirection = getDirection();
        if (swiperInstance.params.direction !== newDirection) {
            swiperInstance.changeDirection(newDirection);
        }
    });
});
