import MicroModal from 'micromodal';

const blockMosaic = document.querySelectorAll('.block-mosaic');

blockMosaic.forEach((elMosaic) => {
    const btnCardFlip = elMosaic.querySelectorAll('.flip');
    const modals = elMosaic.querySelectorAll('.modal');

    // Function to initialize MicroModal
    const cardHandleClick = () => {
        btnCardFlip.forEach((el) => {
            const actionBtns = el.querySelectorAll('.action-btn');
            actionBtns.forEach((actionBtn) => {
                const modalId = actionBtn.getAttribute('data-modal-id');
                actionBtn.addEventListener('click', () => {
                    if (window.innerWidth <= 768) {
                        MicroModal.show(modalId);
                    } else {
                        el.classList.toggle('flipped');
                    }
                });
            });
        });
    };
    // Initialize the modal only once on load if needed
    cardHandleClick();

    // Add a resize listener to handle viewport changes dynamically
    window.addEventListener('resize', () => {
        if (window.innerWidth > 768) {
            modals.forEach((element) => {
                element.classList.remove('is-open');
            });
        } else {
            btnCardFlip.forEach((element) => {
                element.classList.remove('flipped');
            });
        }
    });
});
