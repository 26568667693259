import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

const swiperValues = document.querySelectorAll('.block-values-list');
const btnPrev = document.querySelector('.block-values .swiper-button-prev');
const btnNext = document.querySelector('.block-values .swiper-button-next');

swiperValues.forEach((element) => {
    new Swiper(element, {
        modules: [Navigation, Autoplay],
        slidesPerView: 2,
        spaceBetween: 16,
        loop: true,
        autoplay: {
            delay: 1500,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: btnPrev,
            prevEl: btnNext,
        },
        breakpoints: {
            768: {
                slidesPerView: 'auto',
                spaceBetween: 24,
            },
            1600: {
                slidesPerView: 6,
            },
        },
    });
});
