import MicroModal from 'micromodal';

const blockValues = document.querySelectorAll('.block-values');

blockValues.forEach((elValue) => {
    const btnCardFlip = elValue.querySelectorAll('.flip');
    const modals = elValue.querySelectorAll('.modal');

    // Function to initialize MicroModal
    const cardHandleClick = () => {
        btnCardFlip.forEach((el) => {
            const slideBtns = el.querySelectorAll('.action-btn');
            slideBtns.forEach((slideBtn) => {
                const modalId = slideBtn.getAttribute('data-modal-id');
                slideBtn.addEventListener('click', () => {
                    if (window.innerWidth <= 768) {
                        MicroModal.show(modalId);
                    } else {
                        el.classList.toggle('flipped');
                    }
                });
            });
        });
    };
    // Initialize the modal only once on load if needed
    cardHandleClick();

    // Add a resize listener to handle viewport changes dynamically
    window.addEventListener('resize', () => {
        if (window.innerWidth > 768) {
            modals.forEach((element) => {
                element.classList.remove('is-open');
            });
        } else {
            btnCardFlip.forEach((element) => {
                element.classList.remove('flipped');
            });
        }
    });
});
