import Swiper from 'swiper';
import { Navigation, Pagination, EffectCards } from 'swiper/modules';

const swiperCardFlip = document.querySelectorAll('.swiper-card-flip');

swiperCardFlip.forEach((element) => {
    new Swiper(element, {
        modules: [Navigation, Pagination, EffectCards],
        effect: 'cards',
        keyboard: true,
        loop: true,
        noSwiping: true,
        centeredSlides: true,
        initialSlide: 2,
        noSwipingClass: '.swiper-wrapper',
        pagination: {
            el: element.querySelector('.swiper-pagination'),
        },
        navigation: {
            nextEl: element.querySelector('.swiper-button-next'),
            prevEl: element.querySelector('.swiper-button-prev'),
        },
        cardsEffect: {
            perSlideOffset: 18,
            perSlideRotate: 0,
            rotate: false,
            slideShadows: false,
        },
    });
});

const btnCardFlip = document.querySelectorAll('.btn-flip-card');

btnCardFlip.forEach((el) => {
    el.addEventListener('click', () => {
        const card = el.closest('.swiper-slide');
        card.classList.toggle('flipped');
    });
});
