import Cookies from 'js-cookie';
import { up } from 'slide-element';

const dismissedAlerts = JSON.parse(Cookies.get('dismissedAlerts') || null) || [];
const $alerts = document.querySelectorAll('.alert');
const $body = document.body;

$alerts.forEach(($alert) => {
    const alertId = $alert.dataset.id;
    const $btnClose = $alert.querySelector('.btn-close');

    if (dismissedAlerts.includes(alertId)) $alert.style.display = 'none';

    $btnClose.addEventListener('click', () => {
        up($alert);
        dismissedAlerts.push(alertId);
        Cookies.set('dismissedAlerts', JSON.stringify(dismissedAlerts), {
            expires: 365,
        });

        if (isElementVisible($alert)) {
            $body.classList.add('no-alerts');
        }
    });
});

// Onload check if there are alerts in the page if not add class no-alerts
if (!document.querySelector('.alerts')) {
    $body.classList.add('no-alerts');
}
// Onload check if the cookie dismissedAlerts exist if so add class no-alerts
if (Cookies.get('dismissedAlerts')) {
    $body.classList.add('no-alerts');
}

function isElementVisible(element) {
    return element.offsetParent !== null;
}
