import Swiper from 'swiper';
import confetti from 'canvas-confetti';
import { Navigation, Pagination, EffectCards } from 'swiper/modules';

const swiperQuiz = document.querySelectorAll('.block-quiz-swiper');

const answers = [
    { key: 'slush_color', value: '' },
    { key: 'sign', value: 'Aquarius' },
    { key: 'team_activity', value: '' },
    { key: 'pet1', value: '' },
    { key: 'pet2', value: '' },
    { key: 'experience', value: '' },
];

const confettiSettings = {
    spread: 360,
    ticks: 50,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    colors: ['ff5f00', 'ffaf91', 'e0e9fd'],
};

const shootConfetti = () => {
    confetti({
        ...confettiSettings,
        particleCount: 40,
        scalar: 1.2,
        shapes: ['star'],
    });
};

swiperQuiz.forEach((element) => {
    const swiperInstance = new Swiper(element, {
        modules: [Navigation, Pagination, EffectCards],
        effect: 'cards',
        keyboard: true,
        loop: true,
        noSwiping: true,
        noSwipingClass: 'swiper-slide',
        cardsEffect: {
            perSlideOffset: 8,
            perSlideRotate: 1,
            rotate: true,
            slideShadows: false,
        },
    });

    const slides = element.querySelectorAll('.swiper-slide');

    slides.forEach((slide) => {
        const nextButton = slide.querySelector('.swiper-button-next');
        const prevButton = slide.querySelector('.swiper-button-prev');

        const updateNextButtonState = () => {
            if (!nextButton) return;

            const radioGroup = slide.querySelectorAll('input[type="radio"]');
            const textField = slide.querySelector('input[type="text"]');
            const selectField = slide.querySelector('select');
            const petsNameDiv = slide.querySelector('.pets-name');
            const nextStepBtn = petsNameDiv?.querySelector('.next-step-btn');

            let isNextEnabled = false;

            if (radioGroup.length > 0) {
                isNextEnabled = Array.from(radioGroup).some((radio) => radio.checked);
            }

            if (textField) {
                isNextEnabled = textField.value.trim().length > 0;
            }

            if (selectField) {
                isNextEnabled = selectField.value !== '';
            }

            const wasDisabled = nextButton.disabled;
            nextButton.disabled = !isNextEnabled;

            if (!nextButton.disabled && wasDisabled) {
                swiperInstance.slideNext();
            }

            const petsRadioGroup = slide.querySelectorAll('input[name="pet1"]');
            const selectedPetOption = Array.from(petsRadioGroup)
                .find((radio) => radio.checked)?.value;

            if (selectedPetOption === 'yes' && petsNameDiv) {
                petsNameDiv.style.display = 'block';

                if (textField && nextStepBtn) {
                    nextStepBtn.disabled = textField.value.trim().length === 0;
                    textField.addEventListener('input', () => {
                        nextStepBtn.disabled = textField.value.trim().length === 0;
                    });
                }

                if (nextStepBtn) {
                    nextStepBtn.addEventListener('click', () => {
                        nextButton.disabled = false;
                        swiperInstance.slideNext();
                    }, { once: true });
                }
            } else if (petsNameDiv) {
                petsNameDiv.style.display = 'none';
            }

            if (selectedPetOption === 'no') {
                nextButton.disabled = false;
                swiperInstance.slideNext();
            }
        };

        const radioGroup = slide.querySelectorAll('input[type="radio"]');
        radioGroup.forEach((radio) => {
            radio.addEventListener('change', updateNextButtonState);
        });

        const selectField = slide.querySelector('select');
        if (selectField) {
            selectField.options[0].disabled = true;
            selectField.addEventListener('change', updateNextButtonState);
        }

        if (nextButton) {
            nextButton.addEventListener('click', () => {
                swiperInstance.slideNext();
            });
        }

        if (prevButton) {
            prevButton.addEventListener('click', () => {
                swiperInstance.slidePrev();
            });
        }

        const radioGroupExp = slide.querySelectorAll('input[name="experience"]');
        const submitButton = slide.querySelector('.submit-quiz');

        if (radioGroupExp && submitButton) {
            const updateButtonState = () => {
                const isChecked = Array.from(radioGroup).some((radio) => radio.checked);
                submitButton.disabled = !isChecked;
            };

            radioGroup.forEach((radio) => {
                radio.addEventListener('change', updateButtonState);
            });

            updateButtonState();
        }
    });

    // Prevent form submission on Enter key
    document.querySelector('.block-quiz-form').addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    });

    element.querySelector('.submit-quiz').addEventListener('click', (e) => {
        e.preventDefault();
        swiperInstance.slideNext();

        slides.forEach((slide) => {
            const inputs = slide.querySelectorAll('input, select');
            inputs.forEach((input) => {
                const key = input.name;
                const answer = answers.find((item) => item.key === key);

                if (answer) {
                    if (input.type === 'radio' && input.checked) {
                        answer.value = input.value;
                    } else if (input.type === 'text') {
                        answer.value = input.value.trim();
                    } else if (input.tagName.toLowerCase() === 'select') {
                        answer.value = input.value;
                    }
                }
            });
        });

        fetch(config.ajaxurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                action: 'libeo_openai_quiz_answers',
                answers: JSON.stringify(answers),
            }),
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        }).then((data) => {
            const quizMessageWrapper = document.querySelector('.quiz-message');
            const quizResponse = quizMessageWrapper?.querySelector('.quiz-response');
            if (quizResponse) {
                quizResponse.textContent = data;
                quizMessageWrapper.style.display = '';
                setTimeout(shootConfetti, 0);
                setTimeout(shootConfetti, 100);
                setTimeout(shootConfetti, 200);
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    });
});
