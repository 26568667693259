import anime from 'animejs/lib/anime';

let scrollTimeout;
let lastScrollPosition = 0;
const btnJobs = document.querySelectorAll('.btn-jobs');
const btnQuiz = document.querySelector('.btn-quiz');
const sideBtns = document.querySelectorAll('.side-btn');
const viewportHeight = window.innerHeight;

const updateButtonPosition = () => {
    const scrollPosition = window.scrollY;
    const newPos = scrollPosition + viewportHeight / 2 - 100; // 100px of offset to center buttons
    const isScrollingDown = scrollPosition > lastScrollPosition;
    const durationJob = isScrollingDown ? 650 : 400;
    const durationQuiz = isScrollingDown ? 400 : 650;
    const offset = window.innerWidth <= 768 ? 156 : 172; // Adjust the offset based on screen width

    anime({
        targets: btnJobs,
        top: `${newPos}px`,
        easing: 'easeOutExpo',
        duration: durationJob,
    });

    anime({
        targets: btnQuiz,
        top: `${newPos + offset}px`,
        easing: 'easeOutExpo',
        duration: durationQuiz,
    });

    lastScrollPosition = scrollPosition;
};

sideBtns.forEach((btn) => {
    btn.addEventListener('click', (event) => {
        event.preventDefault();
        const targetId = btn.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    });
});

window.addEventListener('scroll', () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(updateButtonPosition, 1);
});
