import MicroModal from 'micromodal';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const blockOffices = document.querySelectorAll('.block-offices');

blockOffices.forEach((blockOffice) => {
    const offices = blockOffice.querySelectorAll('.office');
    const officesSwiper = blockOffice.querySelectorAll('.swiper');
    offices.forEach((office) => {
        office.addEventListener('click', () => {
            const officeModalId = office.getAttribute('data-modal-office');
            MicroModal.show(officeModalId);
        });
    });
    officesSwiper.forEach((officeSwiper) => {
        const modal = officeSwiper.closest('.modal-office');
        new Swiper(officeSwiper, {
            modules: [Navigation, Pagination],
            keyboard: true,
            loop: true,
            noSwiping: false,
            noSwipingClass: '.swiper-wrapper',
            navigation: {
                nextEl: modal.querySelector('.swiper-button-next'),
                prevEl: modal.querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: modal.querySelector('.swiper-pagination'),
                type: 'fraction',
            },
        });
    });
});
